.artsection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $text;
  font-size: 16px;
  margin: 30px 60px 80px;
  @include desktop() {
    font-size: 20px;
    margin: 80px 60px;
  }

  &__title {
    margin-bottom: 30px;
    color: $darkgreen;
    background: $background;
    padding: 30px;
    border-radius: 10px;
    font-size: 16px;
    text-align: center;
    @include desktop() {
      font-size: 22px;
    }
  }
  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    &--article {
      text-align: center;
      list-style: none;
      color: $darkgreen;
      font-weight: bolder;
      text-decoration: none;
      margin: 15px 0;
      &:hover {
        background-color: $ligthcolor;
        border-radius: 10px;
      }
    }
    &--span {
      color: $darkcolor;
    }
  }
}
