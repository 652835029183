/* Estilos base */
.menu {
  position: relative;
}

.hamburger-button {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

/* Estilo del menú */
.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 20px;
}

.menu-item {
  position: relative;
}

.menu-link,
.dropdown-toggle {
  text-decoration: none;
  cursor: pointer;
}

.dropdown-toggle {
  background: none;
  border: none;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Source Code Pro', monospace;
  font-weight: bolder;
  @include desktop() {
    font-size: 20px;
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px 0;
  margin: 0;
  z-index: 10;
}

.dropdown-item {
  padding: 5px 15px;
}

.dropdown-link {
  text-decoration: none;
  color: #333;
}

.dropdown-link:hover {
  background-color: #f0f0f0;
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .hamburger-button {
    display: block;
  }

  .menu-list {
    flex-direction: column;
    gap: 10px;
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    z-index: 10;
  }

  .menu-list.open {
    display: flex;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    padding-left: 20px;
  }
}

.mainmenu {
  display: flex;
  justify-content: center;
  &__minilogo {
    margin-top: 50px;
    width: 80px;
    border: 5px solid $ligthcolor;
    border-radius: 100%;
  }
  &__containermenu {
    display: flex;
    align-items: center;
    justify-content: center;
    &--button {
      margin: 24px 14px 0 -37px;
      border: none;
      background: none;
      fill: $darkgreen;
      @include desktop() {
        display: none;
      }
    }
    &--title {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      text-transform: uppercase;
      font-family: $header;
      font-size: 17px;
      font-weight: bolder;
      color: $darkgreen;
      @include desktop() {
        margin-top: 30px;
        font-size: 38px;
      }
    }
  }
  &__subtitle {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    text-transform: uppercase;
    font-size: 11px;
    font-family: $header;
    color: $ligthgreen;
    @include desktop() {
      font-size: 18px;
    }
  }
}

.nav {
  @include desktop() {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
  &__link {
    text-decoration: none;
    color: #63cbb2;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__list {
    text-transform: uppercase;
    font-family: $header;
    height: 0vh;
    opacity: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    transition: all 0.5s ease;
    background-color: opacify($color: #e2f7ecba, $amount: 0);
    @include desktop() {
      width: 100%;
      display: flex;
      flex-direction: inherit;
      justify-content: space-around;
      height: 8vh;
      background-color: transparent;
      padding: 40px 0 200px 0;
    }

    &--item {
      list-style: none;
      color: $darkcolor;
      font-weight: bolder;
      font-size: 14px;
      width: 100%;
      text-align: center;
      @include desktop() {
        font-size: 20px;
        margin: 0px 4px;
      }

      &:hover {
        color: $link;
        background-color: $ligthcolor;
        border-radius: 10px;
      }
    }
  }
}

.menu {
  width: 20px;
}
.isActive {
  height: 35vh;
  opacity: 1;
  margin-bottom: 20px;
  width: 100%;
  @include desktop() {
    margin-bottom: 0px;
    padding-top: 10px;
    height: 22vh;
  }
}

/* Estilos para el submenú */
/* Submenú para "Mis servicios" */
.nav__list--services {
  position: relative;
}

/* Submenú */
.nav__submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: $ligthcolor;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  list-style: none;
  z-index: 10;
  width: 100%;
}

/* Mostrar el submenú al pasar el cursor */
.nav__list--services:hover .nav__submenu {
  display: block;
}

/* Elementos del submenú */
.nav__submenu--item {
  padding: 0;
  color: $darkcolor;
  font-family: $header;
  font-size: 14px;
  font-weight: bolder;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Hover en el submenú */

.nav__submenu--item:hover a {
  background-color: $link;
  color: grey !important;
  border-radius: 5px;
}

.nav__link {
  @include desktop() {
    padding: 10px 0;
  }
}
