.cursossection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  @include desktop() {
    font-size: 20px;
    margin: 0px 60px;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;
    color: $darkgreen;
    background: $background;
    padding: 30px;
    border-radius: 10px;
    font-family: $text;
    font-size: 16px;
    text-align: center;
    @include desktop() {
      font-size: 22px;
    }
    &--subtitle {
      margin-bottom: 10px;
      font-size: 16px;
      @include desktop() {
        font-size: 22px;
      }
    }
  }
  &__listdata {
    margin: 10px 0;

    &--item {
      list-style: none;
    }
    &--item::before {
      content: '\2022';
      position: relative;
      left: -10px;
      font-size: 135%;
      color: $darkcolor;
      display: inline-block;
      margin-left: -10px;
    }
  }
  &__text {
    margin: 10px 15px;
  }

  .emailLink {
    text-decoration: none;
    color: $link;
    font-weight: bolder;
    &:hover {
      background-color: $ligthcolor;
      border-radius: 10px;
    }
  }

  .cursoslist {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    flex-direction: column;
    font-weight: bolder;
    color: #2b4f60;
    margin: 0;
    display: flex;

    @include desktop() {
      font-size: 20px;
      margin: 0 132px;
    }
    &__list {
      margin: 10px 0;
      &--item {
        list-style: none;
        font-weight: bolder;
      }
      &--item::before {
        content: '\2022';
        position: relative;
        left: -10px;
        font-size: 135%;
        color: $darkcolor;
        display: inline-block;
        margin-left: -10px;
      }
    }
  }
  .display {
    display: none;
  }
}

.explanation {
  font-family: $text;
  font-size: 16px;
  flex-direction: column;
  color: $darkgreen;
  margin: 0;
  display: flex;
  font-weight: lighter;
  @include desktop() {
    font-size: 20px;
    margin: 0 132px;
  }
  &__title {
    text-align: center;
  }
}
.green {
  color: $link;
}

.cursoslist__list--title {
  border: solid 1px $link;
  margin-bottom: 20px;
  padding: 30px;
  font-size: 20px;
  @include desktop() {
    font-size: 30px;
  }
}

.displaynone {
  color: transparent;
  height: 0;
}

.cursossection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  @include desktop() {
    font-size: 20px;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;
    color: $darkgreen;
    background: $background;
    padding: 30px;
    border-radius: 10px;
    font-size: 16px;
    @include desktop() {
      font-size: 22px;
    }
  }

  &__img {
    width: 100%;
    margin-bottom: 10px;
    @include desktop() {
      width: 50%;
    }
  }
}
