.piccontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__personalpicture {
    width: 50%;
    position: relative;
    @include desktop {
      width: 20%;
    }
  }
  &__logo {
    display: none;
    @include desktop() {
      display: flex;
      width: 9%;
      margin-top: -15vh;
      margin-left: 326px;
      z-index: 100;
      border: 5px solid $ligthcolor;
      border-radius: 100%;
    }
  }
}

.description {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'Source Code Pro', monospace;
  font-size: 17px;
  font-weight: bolder;
  color: #2b4f60;
  margin-top: 60px;
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
}
