.container {
  display: flex;
  justify-content: center;
  &__minilogo {
    margin-top: 50px;
    width: 80px;
    border: 5px solid $ligthcolor;
    border-radius: 100%;
  }
}
.header {
  &__containermenu {
    display: flex;
    align-items: center;
    justify-content: center;
    &--button {
      margin: 24px 14px 0 -37px;
      border: none;
      background: none;
      fill: $darkgreen;
      @include desktop() {
        display: none;
      }
    }
    &--title {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      text-transform: uppercase;
      font-family: $header;
      font-size: 17px;
      font-weight: bolder;
      color: $darkgreen;
      @include desktop() {
        margin-top: 30px;
        font-size: 38px;
      }
    }
  }
  &__subtitle {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    text-transform: uppercase;
    font-size: 11px;
    font-family: $header;
    color: $ligthgreen;
    @include desktop() {
      font-size: 18px;
    }
  }
}

.nav {
  @include desktop() {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
  &__link {
    text-decoration: none;
    color: #63cbb2;
  }
  &__list {
    text-transform: uppercase;
    font-family: $header;
    height: 0vh;
    opacity: 1;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    transition: all 0.5s ease;
    background-color: opacify($color: #e2f7ecba, $amount: 0);
    @include desktop() {
      width: 83%;
      display: flex;
      flex-direction: inherit;
      justify-content: space-around;
      width: 60%;
      height: 8vh;
      background-color: transparent;
      padding: 10px 0 200px 0;
    }

    &--item {
      list-style: none;
      color: $darkcolor;
      font-weight: bolder;
      font-size: 14px;
      margin: 6px 4px;
      @include desktop() {
        font-size: 20px;
        margin: 0px 4px;
      }

      &:hover {
        color: $link;
        background-color: $ligthcolor;
        border-radius: 10px;
      }
    }
  }
}

.menu {
  width: 20px;
}
.isActive {
  height: 25vh;
  opacity: 1;
  margin-bottom: 20px;
  @include desktop() {
    height: 10vh;
    margin-bottom: 0px;
  }
}

/* Estilos para el submenú */
/* Submenú para "Mis servicios" */
.nav__list--services {
  position: relative;
}

/* Submenú */
.nav__submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: $ligthcolor;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  list-style: none;
  z-index: 10;
  min-width: 200px;
}

/* Mostrar el submenú al pasar el cursor */
.nav__list--services:hover .nav__submenu {
  display: block;
}

/* Elementos del submenú */
.nav__submenu--item {
  padding: 10px 20px;
  color: $darkcolor;
  font-family: $header;
  font-size: 14px;
  font-weight: bolder;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Hover en el submenú */
.nav__submenu--item:hover {
  background-color: $link;
  color: grey;
  border-radius: 5px;
}
