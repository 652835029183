@import './core/reset.scss';
@import './core/variables.scss';
@import './core/mixins.scss';

@import './components/header.scss';
@import './components/home.scss';
@import './components/footer.scss';
@import './components/articles.scss';
@import './components/diagnostic.scss';
@import './components/team.scss';
@import './components/free.scss';
@import './components/ebooks.scss';
@import './components/cursos.scss';
@import './components/employee-advice.scss';
@import './components/supervision.scss';
@import './components/aboutme.scss';
@import './components/consulting.scss';
@import './components/contact.scss';
@import './components/legalpages.scss';
@import './components/collapsiblecard.scss';
@import './components/menu.scss';

.note {
  font-size: 15px;
}
