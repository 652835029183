.aboutsection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 60px 30px;
  font-weight: bold;
  @include desktop() {
    font-size: 20px;
    margin: 80px 60px;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;
    color: $darkgreen;
    background: $background;
    padding: 30px;
    border-radius: 10px;
    font-size: 16px;
    @include desktop() {
      font-size: 22px;
    }
  }

  &__img {
    width: 255px;
    margin-bottom: 10px;
    @include desktop() {
      width: 350px;
    }
  }
}

.aboutmesection {
  &__text {
    color: $darkgreen;
    font-family: $text;
    font-size: 16px;
    margin: 0 48px;
    @include desktop() {
      font-size: 20px;
      // margin: 0 338px;
    }
  }
}

.writeme {
  color: $darkgreen;
  background: $background;
  padding: 30px;
  border-radius: 10px;
  font-size: 16px;
  width: fit-content;
  margin: 10px 70px 0;

  @include desktop() {
    font-size: 22px;
    // margin: 0 338px;
    margin-top: 40px;
  }
}

.category {
  margin-bottom: 20px;
}

.category h2 {
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 25px;
}

.category ul {
  list-style-type: none;
  padding: 0;
}

.category ul li {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}
