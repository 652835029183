@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.bold {
  font-weight: bolder;
}
.italic {
  font-style: italic;
}
.underline {
  text-decoration: underline;
}
