.collapsible-card {
  border: 1px solid #ddd;
  margin: 10px 0;

  border-radius: 4px;
  overflow: hidden;
  transition: all 0.3s ease;

  &__header {
    background-color: #f7f7f7;
    padding: 40px;
    cursor: pointer;
    user-select: none;

    h3 {
      margin: 0;
    }
  }

  &__content {
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd;
  }

  &__icon {
    width: 15px;
    margin-top: 5px;
  }
}
