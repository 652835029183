.contactsection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  margin: 30px 60px 80px;
  @include desktop() {
    font-size: 20px;
    margin: 80px 60px;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;
    color: $darkgreen;
    background: $background;
    padding: 30px;
    border-radius: 10px;
    font-size: 16px;
    @include desktop() {
      font-size: 22px;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    list-style: none;
    font-weight: bolder;
    &--item {
      text-align: center;
      color: $darkgreen;
      font-weight: bolder;
      text-decoration: none;
      margin: 20px 0;
      &:hover {
        background-color: $ligthcolor;
        border-radius: 10px;
      }
    }
    &--span {
      color: $darkcolor;
    }
  }
}
.contactlink {
  text-decoration: none;
  color: $link;
  font-weight: bolder;
}

.icon {
  margin-right: 10px;
  color: $darkgreen;
}
