.team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 60px 0px;
  font-weight: bold;
  @include desktop() {
    font-size: 20px;
    margin: 80px 60px;
  }
  &__text {
    color: $darkgreen;
    font-family: $text;
    font-size: 16px;
    margin: 0px;
    @include desktop() {
      font-size: 20px;
      // margin: 0 338px;
    }
  }

  &__title {
    text-align: center;
    color: $darkgreen;
    background: $background;
    padding: 30px;
    border-radius: 10px;
    font-size: 16px;
    @include desktop() {
      font-size: 22px;
    }
  }
}

.team {
  &__listdata {
    margin: 10px 0;

    &--item {
      list-style: none;
    }
    &--item::before {
      content: '\2022';
      position: relative;
      left: -10px;
      font-size: 135%;
      color: $darkcolor;
      display: inline-block;
      margin-left: -10px;
    }
  }
}

.team {
  &__cards {
    font-family: sans-serif;
    text-align: center;
    padding-top: 40px;
    margin: 0 48px;
    @include desktop() {
      font-size: 20px;
      // margin: 0 338px;
    }
    &--text {
      padding: 20px 20px 0px 20px;
    }
  }
  &__img {
    width: 140px;
    margin-bottom: 10px;
    margin-top: 20px;
    @include desktop() {
      width: 200px;
    }
  }
}

.clarification {
  font-size: 14px;
  font-style: italic;
}

.summary {
  margin-top: 40px;
  @include desktop() {
    margin-top: 80px;
  }
}
